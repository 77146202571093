import language from '@/plugins/store/modules/language'
import notificator from '@/plugins/store/modules/notificator'
import auth from '@/plugins/store/modules/auth'
import user from '@/plugins/store/modules/user'
import location from '@/plugins/store/modules/location'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  modules: {
    auth,
    language,
    notificator,
    user,
    location,
  },
  state: {
    isLoading: false,
    useLoading: true,
    openDrawer: false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload
      if (!payload) {
        state.useLoading = true
      }
    },
    SET_USE_LOADING(state, payload) {
      state.useLoading = payload
    },
    switchDrawer(state, payload) {
      state.openDrawer = payload
    },
  },
  actions: {
    DISABLE_LOADING(state) {
      state.commit('SET_USE_LOADING', false)
    },
    ENABLE_LOADING(state) {
      state.commit('SET_USE_LOADING', true)
    },
    switchDrawer(state, payload) {
      state.commit('switchDrawer', payload)
    },
  },
  getters: {},
})

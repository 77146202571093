import '@/plugins/cookies'
import api from '@/plugins/api'
import i18n from '@/plugins/i18n'
import store from '@/plugins/store/store'
import vuetify from '@/plugins/vuetify'
import '@/plugins/vee-validate'
import '@/plugins/filters/money'
import router from '@/router'
import '@/plugins/filters/money'
import Vue from 'vue'
import App from './App.vue'
import DatetimePicker from 'vuetify-datetime-picker'
import '@/plugins/string'

Vue.config.productionTip = false;

Vue.prototype.$api = api;

Vue.use(DatetimePicker);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeCreate() {
    const initLang = this.$store.getters['language/initLang'];
    this.$store.dispatch('language/setLanguage', initLang);
    this.$i18n.locale = this.$store.getters['language/language'];
  },
  render: h => h(App),
}).$mount('#app');

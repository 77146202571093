import apiRequester from '@/plugins/api'

// initial state
const state = () => ({
  user: null,
})

// getters
const getters = {
  userInfo: state => state.user || JSON.parse(localStorage.getItem('userInfo') || '{}'),
}

// mutations
const mutations = {
  setUser: (state, info) => {
    localStorage.setItem('userInfo', JSON.stringify(info))
    state.user = info
  },

  deleteUser: (state) => {
    localStorage.removeItem('userInfo')
    state.user = null
  },
}

// actions
const actions = {
  loadUserInfo: async ({ commit }) => {
    try {
      // load the info using the App requester that injects the header
      const response = await apiRequester.get('/auth/me', { cache: false })
      if (response.status === 200) {
        commit('setUser', response.data)
      }

      return Promise.resolve(response)
    } catch (error) {
      console.error('Error while getting user info', error)
      return Promise.reject(error.response)
    }
  },

  setUserInfo: ({ commit }, payload) => {
    commit('setUser', payload)
  },

  clearUserInfo: ({ commit }) => {
    commit('deleteUser')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

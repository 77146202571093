import Vue from 'vue'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import { double, email, max, min, min_value, max_value, numeric, integer, regex, required, image, size, mimes, length } from 'vee-validate/dist/rules'
import i18n from '@/plugins/i18n'

import en from 'vee-validate/dist/locale/en.json'
import es from 'vee-validate/dist/locale/es.json'

localize({
    en,
    es,
})

localize(i18n.locale)

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('integer', integer)
extend('double', double)
extend('required', required)
extend('min_value', min_value)
extend('max_value', max_value)
extend('regex', regex)
extend('image', image)
extend('size', size)
extend('mimes', mimes)
extend('length', length)
extend('url', {
    validate: (value) => {
        if (!value) {
            return false
        }
        return /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
    },
    message: (field) => {
        return i18n.t('url_format').replace('%s', field)
    },
})
extend('card_number', {
    validate: (value) => {
        if (!value) {
            return false
        }
        return /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/.test(value.replace(/\s/g, ""))
    },
    message: (field) => {
        return i18n.t('credit-card-format').replace('%s', field)
    },
})
extend('expiration', {
    validate: (value) => {
        if (!value) {
            return false
        }
        return /^[0-9]{1,2}\/[2-9][0-9]{2}$/.test(value)
    },
    message: (field) => {
        return i18n.t('expiration-format').replace('%s', field)
    },
})
extend('json', {
    validate: (value) => {
        if (!value) {
            return false
        }

        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }
        return true;
    },
    message: (field) => {
        return i18n.t('json_format').replace('%s', field)
    },
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

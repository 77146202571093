import Vue from 'vue'
import numeral from 'numeral'

Vue.filter('money', (value) => {
  try {
    return numeral(value).format('(0,0.00)')
  } catch (error) {
    console.error('Error formatting number to money.')
    return 'n/a'
  }
})

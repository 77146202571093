import i18n from "@/plugins/i18n";
import store from "@/plugins/store/store";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Login"),
    meta: {
      title: "login",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Logout"),
    meta: {
      title: "logout",
    },
  },
  {
    path: "/password/recover",
    name: "password-recover",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordRecover"),
    meta: {
      title: "password-recover",
    },
  },
  {
    path: "/password/recover/:id",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordChange"),
    meta: {
      title: "password-recover",
    },
  },
  {
    path: "/",
    component: () => import("@/views/Base"),
    meta: {
      title: "welcome",
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Home"),
        meta: {
          title: "welcome",
          auth: true,
        },
      },
      {
        path: "clinics",
        name: "clinics",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Clinics/Index"),
        meta: {
          title: "clinics",
          auth: true,
        },
      },
      {
        path: "clinics/new",
        name: "clinics-new",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Clinics/Form"),
        meta: {
          title: "clinics-.new",
          auth: true,
        },
      },
      {
        path: "clinics/:id/edit",
        name: "clinics-edit",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Clinics/Form"),
        meta: {
          title: "clinics-.edit",
          auth: true,
        },
      },
      {
        path: "clinics/:id/details",
        name: "clinics-details",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Clinics/Details"),
        meta: {
          title: "clinics-.details",
          auth: true,
        },
      },
      {
        path: "sms",
        name: "messages",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Messages"),
        meta: {
          title: "messages",
          auth: true,
        },
      },
      {
        path: "users",
        name: "users",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Index"),
        meta: {
          title: "users",
          auth: true,
        },
      },
      {
        path: "users/new",
        name: "users-new",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Form"),
        meta: {
          title: "users-.new",
          auth: true,
        },
      },
      {
        path: "users/:id/edit",
        name: "users-edit",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Form"),
        meta: {
          title: "users-.edit",
          auth: true,
        },
      },
      {
        path: "users/:id/details",
        name: "users-details",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Users/Details"),
        meta: {
          title: "users-.details",
          auth: true,
        },
      },
      {
        path: "payments/pending",
        name: "pending-payments",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Payments/Pending"),
        meta: {
          title: "pending",
          auth: true,
        },
      },
      {
        path: "payments/archived",
        name: "archived-payments",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Payments/Archived"),
        meta: {
          title: "archived",
          auth: true,
        },
      },
      {
        path: "payments/new",
        name: "payments-new",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Payments/Form"),
        meta: {
          title: "payments-.new",
          auth: true,
        },
      },
      {
        path: "payments/:id/edit",
        name: "payments-edit",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Payments/Form"),
        meta: {
          title: "payments-.edit",
          auth: true,
        },
      },
      {
        path: "payments/:id/details",
        name: "payments-details",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Payments/Details"),
        meta: {
          title: "payments-.details",
          auth: true,
        },
      },
      {
        path: "calls/history",
        name: "calls-history",
        component: () => import(/* webpackMode: "lazy" */ "@/views/CallsHistory"),
        meta: {
          title: "history",
          auth: true,
        },
      },
      {
        path: "calls/live",
        name: "live-calls",
        component: () => import(/* webpackMode: "lazy" */ "@/views/LiveCalls"),
        meta: {
          title: "live-calls",
          auth: true,
        },
      },
      {
        path: "calls/medical-staff",
        name: "medical-staff",
        component: () => import(/* webpackMode: "lazy" */ "@/views/MedicalStaff"),
        meta: {
          title: "medical-staff",
          auth: true,
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import(/* webpackMode: "lazy" */ "@/views/errors/Error404"),
    meta: {
      title: "not_found",
    },
  },
  //End
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    window.scrollTo({ top: 0, left: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("ENABLE_LOADING");

  const token = store.getters["auth/authToken"];
  const storedAuth = store.getters["auth/isLoggedIn"];
  if (to.name === "login" && token) {
    next({ name: "dashboard" });
  } else if (to.matched.some((record) => record.meta.auth)) {
    if (token && !storedAuth) {
      await store.dispatch("auth/deleteAuthCookies", null, { root: true });
      await next({ name: "login", params: { redirectTo: to.fullPath } });
    } else if (to.name !== "login" && !token) {
      next({
        name: "login",
        params: { redirectTo: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = `${to.meta ? `${i18n.t(to.meta.title ? to.meta.title : to.name)} | ` : ""
    } Emdidoc`;
});

export default router;

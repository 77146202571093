import api from '@/plugins/api'

export const countries = {
  load: async function () {
    try {
      const response = await api.get('/countries')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  available: async function () {
    try {
      const response = await api.get('/countries/available')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  states: async function (id) {
    try {
      const response = await api.get(`/countries/${id}/states`)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  matchById: async function (list, id) {
    const matches = list.filter(x => x.id == id);
    return matches.length > 0 ? matches[0] : null;
  },
}

export default countries
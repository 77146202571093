import requestCountries from '../../../requests/countries'

const state = () => ({
  countries: [],
  states: new Map(),
})

const getters = {
  countries: state => state.countries || [],
  states: state => state.states || {},
}

const mutations = {
  setCountries: (state, countries) => {
    state.countries = countries
  },
  setStates: (state, payload) => {
    state.states.set(payload.country_id, payload.states)
  },
}

const actions = {
  loadCountries: async ({ state, commit }) => {
    if (state.countries.length > 0) {
      return Promise.resolve(state.countries)
    }
    try {
      const countries = await requestCountries.available()
      commit('setCountries', countries)
      return Promise.resolve(countries)
    } catch (error) {
      console.error('Error while getting countries info', error)
      return Promise.reject(error.response)
    }
  },
  loadCountryStates: async ({ state, commit }, payload) => {
    if (state.states.has(payload)) {
      return Promise.resolve(state.states.get(payload))
    }
    try {
      const states = await requestCountries.states(payload)
      commit('setStates', { country_id: payload, states: states })

      return Promise.resolve(states)
    } catch (error) {
      console.error('Error while getting states info', error)
      return Promise.reject(error.response)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import i18n from '@/plugins/i18n'

Vue.use(Vuetify)

const theme = {
    primary: '#457b9d',
    secondary: '#7CC7CA',
    accent: '#E63946',
    info: '#00CAE3',
    success: '#0AC229',
    warning: '#EACB00',
    error: '#E63946',
}

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
        dark: localStorage.getItem('dark_theme') === '1',
        themes: {
            dark: theme,
            light: theme,
        },
    },
})

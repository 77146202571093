import VueCookies from 'vue-cookies'
import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'
import { localize } from 'vee-validate'

const state = () => ({
  language: null,
  dayJsLocales: {
    en: () => import('dayjs/locale/en'),
    es: () => import('dayjs/locale/es'),
  },
})

const getters = {
  language: state => state.language,

  initLang: (state) => {
    return state.language || VueCookies.get('language') || process.env.VUE_APP_I18N_LOCALE || 'es'
  },
}

const mutations = {
  setLanguage: (state, language) => {
    if (!['es', 'en'].includes(language)) {
      language = process.env.VUE_APP_I18N_LOCALE || 'es'
    }

    if (state.dayJsLocales[language]) {
      state.dayJsLocales[language]().then(() => dayjs.locale(language))
    }

    i18n.locale = language
    VueCookies.set('language', language, '10d', '/', '', true)
    state.language = language
    localize(i18n.locale)
  },
}

const actions = {
  setLanguage: ({ commit }, lang) => {
    commit('setLanguage', lang)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

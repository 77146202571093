<template>
  <v-app id="app">
    <v-container fluid class="py-0 px-0">
      <router-view></router-view>
    </v-container>
    <v-snackbar v-for="(message, i) in messages" :key="i" :color="message.type" :multi-line="true" timeout="5000"
      :top="true" :value="true">
      {{ message.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text x-small v-bind="attrs" @click="removeMessage(message)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="isLoading">
      <div class="text-center">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "App",
  components: {},
  computed: {
    ...mapState([
      'isLoading',
    ]),
    ...mapGetters({
      messages: "notificator/messages",
    }),
  },
  data: () => ({
  }),
  async created() {
  },
  methods: {
    async removeMessage(item) {
      const index = this.messages.findIndex((element) => element === item)
      if (index > -1) {
        await this.$store.dispatch('notificator/remove', index)
      }
    },
  },
};
</script>

<style lang="scss">
@import "./scss/layout.scss";

.v-overlay {
  z-index: 500;
}
</style>
